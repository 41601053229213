import axios from 'axios'

export const api = axios.create({
  baseURL: 'https://hygienechecks.newaptism.cn',
  // baseURL: 'https://test.newaptism.cn',
})

api.interceptors.request.use(req => {
  const token = localStorage.getItem('token')
  const config = {
    // params: {
    //     token
    // }
  }
  if (!req.headers.hasOwnProperty('token') && token) {
    req.headers.token = token
    req.headers.Authorization = 'Bearer ' + token
  }
  return {
    ...config,
    ...req
  }
}, err => {

  return Promise.reject(err)
})

api.interceptors.response.use(res => {


    if (res.data.code == 0 || res.data.code == 200 || res.status === 200) {
      return Promise.resolve(res.data)
    }
  
  // console.log(res)

}, err => {
  return Promise.reject(err)
})

export default api
