<template>
  <div class="home">
    <div class="max">
      <div class="max_left div_gg">
        <div class="content_left" v-loading="loading">
          <div class="max_left_top">
            <div class="date">
              日期时间：
              <el-date-picker
                v-model="date_time"
                type="datetime"
                placeholder="选择日期时间"
                disabled
              >
              </el-date-picker>
            </div>
            <div class="name_dian">
              门店名称：
              <el-input
                class="input_name"
                placeholder="请输入内容"
                prefix-icon="el-icon-school"
                v-model="storename"
                disabled
              >
              </el-input>
            </div>
          </div>
          <div class="max_left_bottom">
            <el-upload
              class="upload-demo"
              drag
              :action="upURL"
              :on-success="onsuccess"
              :on-progress="onprogress"
              show-file-list
              multiple
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件</div>
            </el-upload>
          </div>
          <el-button type="primary" class="but_add" @click="addimg"
            >提 交</el-button
          >
        </div>
      </div>
      <div class="max_right div_gg">
        <div class="text">好评识别详情：</div>
        <el-table :data="tableData" style="width: 100%" height="80vh">
          <el-table-column label="图片">
            <template slot-scope="scope">
              <!-- <div>
                {{scope.row}}
              </div> -->
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.imageUrl"
                :preview-src-list="[tableData[scope.$index].imageUrl]"
              >
              </el-image>
            </template>
          </el-table-column>
          platform
          <el-table-column prop="platform" label="平台"> </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 50]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../api/index";
export default {
  components: {},
  data() {
    return {
      tableData: [],
      date_time: new Date(),
      flow: "", //流水号
      storename: "", //门店名称
      loading: false, //加载oading
      currentPage4: 1, //页数
      pagesize: 10, //条数
      total: 10, //总条数
      url: "",
      srcList: [
        
      ],
    };
  },
  created() {
    this.extractQueryParams();
    this.getlist();
  },
  methods: {
    handleSizeChange(val) {
      this.pagesize = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getlist();
    },
    //上传中钩子
    onprogress() {
      this.loading = true;
    },
    //上传成功钩子
    onsuccess() {
      this.loading = false;
      this.$message({
        message: "上传成功!",
        type: "success",
      });
      this.getlist();
    },
    //信息查看钩子
    getlist() {
      const ImageData = {
        pageSize: this.pagesize,
        currentPage: this.currentPage4,
        startDay: "",
        endDay: "",
        serialNumber: this.flow,
        store: "",
      };
      api
        .post("/acclaim/selectAcclaimImg", ImageData)
        .then((res) => {
            this.tableData = res.rows;
            this.total = res.total;
            console.log(this.tableData, "<-返回数组?");
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error("错误了,网络或其他问题！请联系管理员处理！");
        });
    },
    //提交全部钩子
    addimg() {
      this.loading = true;
      api
        .get(`/acclaim/submitAcclaimImg?serialNumber=${this.flow}`)
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.$message({
              message: "提交照片成功!",
              type: "success",
            });
          } else {
            this.$message.error(`提交失败！错误码:${res.code}`);
            console.log(res);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error("错误了,网络或其他问题！请联系管理员处理！");
        });
    },
    //截取值
    extractQueryParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      // 获取serialnumber参数的值
      if (urlParams.has("serialnumber")) {
        this.flow = urlParams.get("serialnumber");
      }
      // 获取StoreName参数的值
      if (urlParams.has("StoreName")) {
        this.storename = urlParams.get("StoreName");
      }
    },
    dayin(val) {
      console.log(val, "插槽里数值");
    },
  },
  computed: {
    upURL() {
      return `https://hygienechecks.newaptism.cn/acclaim/acclaimEfficacy?serialNumber=${this.flow}&sellerName=${this.storename}`;
    },
  },
};
</script>
<style lang="scss" soped>
.max {
  display: flex;
}
.div_gg {
  box-sizing: border-box;
  padding: 12px;
  width: 50%;
  height: 100vh;
}
.max_left {
  position: relative;
  // background: #ccc;
}
.max_left_top {
  display: flex;
  margin-bottom: 5%;
  .name_dian {
    width: 300px;
    display: flex;
    align-items: center;
    margin-left: 5%;
    .input_name {
      width: 200px;
    }
  }
}
.max_right {
  border-left: 2px dashed #ccc;
  // background-color: rgb(156, 91, 91);
}
.upload-demo {
  position: relative;
  left: 10%;
}
.but_add {
  position: relative;
  left: 5%;
  top: 10%;
}
.content_left {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 150px 50px;
  width: 800px;
  height: 800px;
}
.text {
  margin: 12px 0;
}
.el-pagination {
  margin-top: 12px;
}
.el-upload__tip {
  width: 300px;
}
</style>